<template>
  <div id="app" style="height:100%">
    <transition :name="transitionName" mode="in-out" @after-enter="afterEnter">
      <keep-alive :exclude="['SearchResult','BookDetail']"> <!-- exclude冒号必须有 name是template中的name非router中的name -->
        <!-- 名字为组件Vue中的name，非router中的name -->
        <router-view ref="child" />
      </keep-alive>
    </transition>

    <mt-tabbar
      v-model="selected"
      v-show="this.$route.meta.index < 10"
      :fixed="tabFixed"
    >
      <mt-tab-item id="/">
        <router-link to="/" replace><i class="fa fa-home"></i>首页</router-link>
      </mt-tab-item>
      <mt-tab-item id="/catgory.html">
        <router-link to="/catgory.html" replace
          ><i class="fa fa-bars"></i>分类</router-link
        >
      </mt-tab-item>
      <mt-tab-item id="/end.html">
        <router-link to="/end.html" replace
          ><i class="fa fa-book"></i>完本</router-link
        >
      </mt-tab-item>
    </mt-tabbar>
  </div>
</template>
<script>
import '@/assets/css/style.less'
export default {
  data() {
    return {
      transitionName: "left",
      selected: "2",
      tabFixed: true,
    };
  },
  mounted(){
    this.selected = this.selected = window.location.pathname;
  },
  methods: {
    afterEnter: function () {
      if (typeof this.$refs["child"].afterEnter == "function")
        this.$refs["child"].afterEnter();
    },
  },
};
</script>
<style lang="less" scoped>
// #app,.mint-tabbar {max-width: 800px;margin:0 auto;}
.mint-tab-item-label {
  a {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: black;
    font-weight: bold;
    text-decoration: none;
    height: fit-content;
    font-size: 0.8rem;
    :hover{
      text-decoration: none;
    }
  }
  i {
    font-size: 1.2rem;
  }
}
.is-selected {
  a {
    color: orange !important;
    text-shadow: 0px 0px 5px orange;
  }
}
</style>
