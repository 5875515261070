<template>
  <div class="home">
    <mt-search v-model="key" ref="mtSearch" @keyup.enter.native="search"></mt-search>
    <Adsense
      data-ad-client="ca-pub-2093034756983913"
      data-ad-slot="6135951403"
    >
    </Adsense>
  </div>
</template>

<script>
export default {
  name: "Home",
  data(){
    return {
      key: ''
    }
  },
  methods: {
    search(){
      
      this.$router.push({name: 'SearchResult', query:{key: this.key}})
    }
  }
};
</script>
<style scoped>
  .home{max-width: 800px;margin:0 auto;}
  .mint-search {
    height: 100%;
    overflow: hidden;
  }
</style>