import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{index: 1}
  },
  {
    path: '/search.html',
    name: 'SearchResult',
    component: () => import('../views/SearchResult.vue'),
    meta:{index: 11}
  },
  {
    path: '/detail/:id.html',
    name: 'BookDetail',
    component: () => import('../views/BookDetail.vue'),
    meta:{index: 11}
  },{
    path: '/read/:bookId/:chapterId.html',
    name: 'Read',
    component: () => import('../views/Read.vue'),
    meta:{index: 11}
  },
  {
    path: '/catgory.html',
    name: 'Catgory',
    component: () => import('../views/Catgory.vue'),
    meta:{index: 2}
  },{
    path: '/end.html',
    name: 'End',
    component: () => import('../views/End.vue'),
    meta:{index: 3}
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
